<template>
  <div class="iq-card">
    <div class="iq-header-title">
      <div class="iq-card-header d-flex justify-content-between">
        <h4>
          Result Insights
        </h4>
      </div>
    </div>

    <div v-if="isResultLoading" class="iq-card-body pt-0 pl-0" :key="'isResultLoading'">
      <span class="m-3 pt-0 pl-1">Result insights are being generated. Please refresh after 30mins</span>
    </div>
    <div v-else-if="resultInsightsFound" class="iq-card-body pt-0 pl-0" :key="resultInsightsFound">
      <div v-for="(chainItem, i) in propResultChainList" :key="i">
        <ul class="pl-4">
          <li>
            <h4>{{chainItem.app_name}}</h4>
            <ul class="pt-0 pl-0">
              <li class="pt-1 pl-0" v-for="(insgtCatList, categoryNamekey) in chainItem.result_insights" :key="categoryNamekey">
                <b class="primary-color">{{categoryNamekey}}</b>
                <ul class="pt-0 pl-2">
                  <li v-for="(insgt, j) of insgtCatList" :key="j">
                    <p class="">{{insgt}}</p>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div v-if="propAppIsATHON" class="pb-4">
        <p class="ml-3">To improve your scores, please contact support team <a href="https://www.gide.ai/test-prep/sat" target="_blank"><b><span class="primary-color">GIDE Testprep</span></b></a>. Click to <a href="https://www.gide.ai/test-prep/sat" target="_blank">Connect</a> with them</p>
      </div>
    </div>
    <b-button v-else class="btn btn-primary m-3" id="gptBtn" @click="generateInsights">
      Generate Insights
    </b-button>
  </div>
</template>

<script>
import { AppResults } from "../FackApi/api/appResults"

export default {
  name: "QuizResultsInsights",
  data () {
    return {
      resultInsightsFound: false,
      isResultLoading: false
    }
  },
  props: {
    propUserDetails: {
      default: null,
      type: Object
    },
    propResultChainList: {
      default: null,
      type: Array
    },
    propAppIsATHON: {
      default: false
    }
  },
  mounted () {
    try {
      if (this.propResultChainList && this.propResultChainList.length <= 0) {
        this.propResultChainList = null
        return
      }

      // Decode and store in propResultChainList.result.result_insight
      for (let chainItem of this.propResultChainList) {
        this.resultInsightsFound = false

        if (chainItem.result_insights) {
          chainItem.result_insights = JSON.parse(decodeURIComponent(chainItem.result_insights))
          this.resultInsightsFound = true
        }

        if (!this.resultInsightsFound) {
          break
        }
      }
    }
    catch (err) {
      this.propResultChainList = null
      this.resultInsightsFound = false
      console.error("Ex: QuizResultsInsights:", err)
    }
  },
  methods: {
    /**
      * Call API to generate Insights
      */
    async generateInsights () {
      for (let chainItem of this.propResultChainList) {
        if (!chainItem.result_insights) {
          // If Insights not found generate then using the gpt API
          this.isResultLoading = true
          AppResults.gptResultInsights(this, chainItem, this.propUserDetails.user_id)
        }
      }
    }
  }
}
</script>
